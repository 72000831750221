/* @font-face {
  font-family: 'WignersFriend';
  src:
    url('assets/WignersFriendRoman.ttf') format('truetype');
  font-weight: normal;
  font-stretch: normal;
} */
@import 'react-modal-video/css/modal-video.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  h1 {
    @apply font-serif;
  }
  h3 {
    @apply font-serif text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-curious-blue-600 font-bold leading-normal;
  }

  p {
    @apply text-base lg:text-lg xl:text-xl text-green-haze-950 font-normal text-left leading-normal;
  }
}

.modal-video {
  @apply cursor-default;
}

.modal-video-body {
  max-width: 1200px;
}
