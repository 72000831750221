/* Define the animation for the cube */
@keyframes scaleCube {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Define the animation for the paths */
@keyframes drawPaths {
  0% {
    stroke-dashoffset: 1000; /* Adjust the value based on the length of your paths */
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* Define the animation for the rects */
@keyframes scaleRects {
  0% {
    opacity: 0;
    /* transform: scale(0); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

/* Define the animation for the text */
@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#blockchain-animation #cube {
  /* stroke-dasharray: 1000; /* Adjust the value based on the length of your paths */
  /* stroke-dashoffset: 1000;  */
  fill: rgb(229 239 213);
  stroke: #14ace3;
  strokeWidth: 4.17px;
}

#blockchain-animation path:not(#cube) {
  stroke-dasharray: 1000; /* Adjust the value based on the length of your paths */
  stroke-dashoffset: 1000;
  fill: none;
  stroke: #14ace3;
  strokeWidth: 4.17px;
}

#blockchain-animation rect, #blockchain-animation text {
  opacity: 0;
}

#blockchain-animation text {
  font-size: 20px;
  @apply text-xl md:text-3xl
}

/* Apply animations when animation has started */
.animation-started #cube {
  transform-origin: center;
  animation: scaleCube 1s ease forwards ;
}

.animation-started path {
  transform-origin: center;
  background: #fff;
  animation: drawPaths 2.5s ease forwards ;
  animation-delay: 1s;
}

.animation-started rect {
  animation: scaleRects 1s ease forwards ;
  animation-delay: 1.7s;
}

.animation-started text {
  transform-origin: center;
  animation: fadeInText 1s ease forwards ;
  animation-delay: 2.5s;
}
